import React from 'react'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import { getUser } from '../services/auth'

const ManageAccount = () => (
  <>
    <SEO
      title="Manage Account"
      description="Manage Account description goes here"
    />
    <Container>
      <PageTitle>Manage Account</PageTitle>
      <ul>
        <li>Name: {getUser().username}</li>
        <li>E-mail: {getUser().email}</li>
      </ul>
    </Container>
  </>
)

export default ManageAccount
