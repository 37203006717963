import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/Layout'
import PrivateRoute from '../components/PrivateRoute'
import ManageAccount from '../components/ManageAccount'

const AccountApp = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/account/manage" component={ManageAccount} />
    </Router>
  </Layout>
)

export default AccountApp
